@import url("https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bad+Script&family=Nothing+You+Could+Do&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Hind+Siliguri:wght@300;400;500;600;700&family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Jost:ital,wght@0,100..900;1,100..900&family=Karla:ital,wght@0,200..800;1,200..800&family=M+PLUS+1p&family=Manrope:wght@200..800&family=Maven+Pro:wght@400..900&family=Nanum+Gothic&family=Outfit:wght@100..900&family=PT+Sans+Narrow:wght@400;700&family=Quicksand:wght@300..700&family=Raleway:ital,wght@0,100..900;1,100..900&family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&family=Space+Grotesk:wght@300..700&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap");

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Abel", sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #333;
  background: linear-gradient(135deg, #fbd3e9 0%, #bb377d 100%);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  flex-direction: column;
}

#root {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.App {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.Container {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  padding-bottom: 10px;
}

.container {
  margin-top: 110px;
  padding-bottom: 10px;
}

/* ...rest of your CSS code... */

h1,
h2 {
  color: #343a40;
}

h1 {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 20px;
}

h2 {
  font-size: 1.75rem;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 20px;
}

.form-inline .form-control {
  border-radius: 20px;
  padding: 10px;
  border: 1px solid #ced4da;
  margin-right: 1rem;
}

.form-inline {
  display: flex;
  align-items: center;
}

.btn {
  border-radius: 50px;
  font-size: 1rem;
  font-weight: bold;
  margin: 0 5px;
}

.btn-primary,
.btn-outline-light,
.btn-share-cart {
  color: #fff;
  border: none;
  /* background-color: #000000;
  border-color: #000000; */
  margin-top: 5px;
}

.btn-primary:hover,
.btn-outline-light:hover,
.btn-share-cart:hover {
  background-color: #ffffff;
  border-color: #ffffff;
}

.card {
  border: none;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-title {
  font-size: 1.25rem;
  font-weight: bold;
}

.card-text {
  font-size: 1rem;
  color: #666;
}

.card img {
  border-radius: 15px 15px 0 0;
}

.card-body {
  padding: 20px;
}

.react-select-container {
  margin-top: 10px;
}

.react-select__control,
.react-select__menu {
  border-radius: 20px;
  border: 1px solid #ced4da;
}

.modal-content {
  border-radius: 15px;
}

.modal-header {
  background-color: #343a40;
  color: #fff;
  border-bottom: none;
  border-radius: 15px 15px 0 0;
}

.modal-footer {
  border-top: none;
  justify-content: center;
}

.modal-title {
  font-weight: bold;
}

.modal-footer .btn-secondary {
  border-radius: 50px;
}

.scrollTop {
  position: fixed;
  right: 20px;
  bottom: 20px;
  height: 40px;
  width: 40px;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1000;
}

.scrollTop:hover {
  background-color: #000000;
}

@media (max-width: 1200px) {
  .container {
    padding: 0 15px;
  }
}

@media (max-width: 992px) {
  .form-inline {
    flex-direction: column;
  }

  .form-inline .form-control {
    margin-bottom: 10px;
    width: 100%;
  }

  .navbar-brand {
    flex-grow: 1;
  }

  .navbar-nav {
    margin-top: 10px;
  }
}

@media (max-width: 768px) {
  .form-group {
    margin-bottom: 1rem;
  }

  .form-inline .form-control {
    margin-bottom: 10px;
    width: 100%;
    margin-right: 0;
  }

  .navbar-nav {
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
  }

  .navbar-collapse {
    justify-content: space-between;
  }

  .btn {
    width: 100%;
    margin-bottom: 10px;
  }

  .card {
    margin-bottom: 15px;
  }
}

@media (max-width: 576px) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  .btn {
    font-size: 0.875rem;
    padding: 8px 16px;
  }

  .navbar-brand {
    font-size: 1.25rem;
  }

  .form-group label {
    font-size: 0.875rem;
  }

  .form-group .form-control {
    font-size: 0.875rem;
  }

  .card-title {
    font-size: 1rem;
  }

  .card-text {
    font-size: 0.875rem;
  }

  .scrollTop {
    height: 35px;
    width: 35px;
    font-size: 1.25rem;
  }

  .navbar-brand .logo {
    margin-right: 5px; /* Adjust this value as needed */
    width: 100px; /* Adjust this value as needed */
    height: auto; /* Adjust this value as needed */
  }

  /* handy view */
  .navbar-brand .brand-text {
    font-size: 2rem !important; /* Ensuring this style is applied */
    font-family: "Nothing You Could Do", cursive !important;
    font-weight: 400;
    font-style: normal;
  }
  .category-field {
    justify-content: flex-end; /* Ensure button is aligned to the right */
  }

  .category-field input[type="date"] {
    padding-right: 2.5rem; /* Add a bit more padding for smaller screens */
  }

  .category-field button {
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
  }
}

h3 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #343a40;
}

.button-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: #007bff;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.button-icon:hover {
  color: #0056b3;
}

.form-group-row {
  display: flex;
  justify-content: center; /* Centers the form groups horizontally */
  flex-wrap: wrap; /* Allows the items to wrap to the next line if they overflow */
  gap: 20px; /* Adds space between the items */
}

.category-field {
  text-align: center;
  margin-bottom: 15px; /* Adjust as needed */
}

.category-field label {
  display: block;
  margin-bottom: 5px; /* Adjust as needed */
}

.upload-image-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.card-img-top {
  max-width: 100%;
  max-height: 450px;
  object-fit: cover;
  object-position: center;
  image-rendering: -webkit-optimize-contrast; /* Chrome, Safari /
  image-rendering: crisp-edges; / Firefox /
  image-rendering: pixelated; / Standard /
  filter: blur(0px); / Slight blur to smooth out edges */
}

.btn-custom-success,
.btn-custom-info,
.btn-custom-primary,
.btn-custom-danger {
  color: white;
}

.btn-custom-success {
  background-color: #28a745; /* Custom success color */
  border-color: #28a745; /* Custom success border color */
}

.btn-custom-info {
  background-color: #17a2b8; /* Custom info color */
  border-color: #17a2b8; /* Custom info border color */
}

.btn-custom-edit {
  background-color: blue;
  border-color: blue;
}

.btn-custom-primary {
  background-color: #54cba2; /* Custom primary color */
  border-color: #ffffff; /* Custom primary border color */
}

.btn-custom-danger {
  background-color: #dc3545; /* Custom danger color */
  border-color: #dc3545; /* Custom danger border color */
}

/* Optionally, you can add hover and active states as well */
.btn-custom-success:hover,
.btn-custom-success:focus,
.btn-custom-success:active {
  background-color: #218838; /* Darker success color */
  border-color: #1e7e34; /* Darker success border color */
}

.btn-custom-info:hover,
.btn-custom-info:focus,
.btn-custom-info:active {
  background-color: #138496; /* Darker info color */
  border-color: #117a8b; /* Darker info border color */
}

.btn-custom-primary:hover,
.btn-custom-primary:focus,
.btn-custom-primary:active {
  background-color: #ffffff; /* Darker primary color */
  border-color: #ffffff; /* Darker primary border color */
}

.btn-custom-danger:hover,
.btn-custom-danger:focus,
.btn-custom-danger:active {
  background-color: #c82333; /* Darker danger color */
  border-color: #bd2130; /* Darker danger border color */
}

.btn-custom-edit:hover,
.btn-custom-edit:focus,
.btn-custom-edit:active {
  background-color: blue; /* Darker danger color */
  border-color: blue; /* Darker danger border color */
}

.fixed-width {
  width: 200px; /* Adjust the width as needed */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.brand-text {
  display: inline-block;
  position: relative;
  white-space: nowrap; /* Prevent text wrapping */
  font-size: 3.5rem;
  font-family: 'Nothing You Could Do', cursive;
  font-weight: 400;
  font-style: normal;
}

.brand-text::after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 2px;
  background-color: transparent;
  transform: scaleX(0);
  transition: transform 0.5s ease;
}

.brand-text:hover::after {
  transform: scaleX(1);
  background-color: currentColor;
}

@keyframes fadeInOut {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.brand-text span {
  display: inline-block;
  animation: fadeInOut 5s infinite;
}

.modal-support-background .modal-content {
  background-color: #e0f7fa !important; /* Light blue background /
  color: #000; / Black text color /
  border-radius: 17px; / Rounded corners */
}

/* Custom button styles for the support form */
.btn-support-primary {
  background-color: #007bff !important;
  border-color: #007bff !important;
  color: white !important;
  margin-top: 5px;
}

.btn-support-secondary {
  background-color: #6c757d !important;
  border-color: #6c757d !important;
  color: white !important;
  margin-top: 5px;
}

/* Custom background for the cart modal */
.modal-cart-background .modal-content {
  background-color: #f3ffff !important; /*important!!!!!!!!! */
  color: #000; /* Black text color */
  border-radius: 17px; /* Rounded corners */
}

/* Custom background for the wishlist modal */
.modal-wishlist-background .modal-content {
  background-color: #f3ffff !important;
  color: #000; /* Black text color */
  border-radius: 17px; /* Rounded corners */
  margin-top: 300px;
}

/* Custom button styles for the cart modal */
.btn-cart-primary {
  background-color: red !important;
  border-color: red !important;
  color: white !important;
}

.btn-cart-secondary {
  background-color: blue !important;
  border-color: blue !important;
  color: white !important;
}

/* Custom button styles for the wishlist modal */
.btn-wishlist-primary {
  background-color: red !important;
  border-color: red !important;
  color: white !important;
}

.btn-wishlist-secondary {
  background-color: #6c757d !important;
  border-color: #6c757d !important;
  color: white !important;
}

/* Custom backdrop for the cart modal */
.modal-cart-background .modal-backdrop {
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ) !important; /* Semi-transparent black backdrop */
}

/* Custom backdrop for the wishlist modal */
.modal-wishlist-background .modal-backdrop {
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ) !important; /* Semi-transparent black backdrop */
}

/* Custom modal header for the cart modal */
.modal-cart-background .modal-header {
  background-color: #424242 !important; /* Dark background for the header /
  color: #fff !important; /* White text color /
  border-bottom: none; /* Remove bottom border */
}

/* Custom modal header for the wishlist modal */
.modal-wishlist-background .modal-header {
  background-color: #424242 !important; /* Dark background for the header */
  color: #fff !important; /* White text color */
  border-bottom: none; /* Remove bottom border */
}

/* Custom modal body for the cart modal */
.modal-cart-background .modal-body {
  background-color: #f3ffff !important; /* Light blue background */
}

/* Custom modal body for the wishlist modal */
.modal-wishlist-background .modal-body {
  background-color: #f3ffff !important; /* Light pink background */
}

.category-section {
  margin-bottom: 20px; /* General margin for all sections */
}

.category-section:last-child {
  margin-bottom: 100px; /* Specific margin for the last section */
}

/* Animation Classes */
.animate {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.animate-visible {
  opacity: 1;
  transform: translateY(0);
}

.landing-page .header,
.landing-page .main,
.landing-page .features-section,
.landing-page .history-section,
.landing-page .pricing-section,
.landing-page .text-center {
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.landing-page .header.animate-visible,
.landing-page .main.animate-visible,
.landing-page .features-section.animate-visible,
.landing-page .history-section.animate-visible,
.landing-page .pricing-section.animate-visible,
.landing-page .text-center.animate-visible {
  opacity: 1;
  transform: translateY(0);
}

/* Custom button styles for specific buttons */
.btn-custom-danger.button,
.btn-cart-primary.button {
  width: 40px; /* Adjusted size */
  height: 40px; /* Adjusted size */
  border-radius: 50%;
  background-color: rgb(20, 20, 20);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.164);
  cursor: pointer;
  transition: width 0.3s, background-color 0.3s, border-radius 0.3s;
  overflow: hidden;
  position: relative;
}

.btn-custom-danger.button .svgIcon,
.btn-cart-primary.button .svgIcon {
  width: 20px;
  transition: width 0.3s, transform 0.3s;
}

.btn-custom-danger.button .svgIcon path,
.btn-cart-primary.button .svgIcon path {
  fill: white;
}

.btn-custom-danger.button:hover,
.btn-cart-primary.button:hover {
  width: 120px; /* Adjusted size */
  border-radius: 50px;
  background-color: rgb(255, 69, 69);
}

.btn-custom-danger.button:hover .svgIcon,
.btn-cart-primary.button:hover .svgIcon {
  width: 40px;
  transform: translateY(40%);
}

.btn-custom-danger.button::before,
.btn-cart-primary.button::before {
  position: absolute;
  top: -10px; /* Adjusted position */
  content: attr(data-text);
  color: white;
  font-size: 2px;
  transition: font-size 0.3s, transform 0.3s, opacity 0.3s;
  opacity: 0;
}

.btn-custom-danger.button:hover::before,
.btn-cart-primary.button:hover::before {
  font-size: 12px; /* Adjusted size */
  opacity: 1;
  transform: translateY(10px); /* Adjusted position */
}

/* Custom styles for the support form modal */
/* Custom styles for the support form modal */
.support-modal-dialog .modal-content {
  background: none;
  border: none;
}

.support-modal-dialog .modal-body {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-container {
  width: 100%;
  max-width: 400px;
  background: linear-gradient(#212121, #212121) padding-box,
    linear-gradient(145deg, transparent 35%, #e81cff, #40c9ff) border-box;
  border: 2px solid transparent;
  padding: 32px 24px;
  font-size: 14px;
  font-family: inherit;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
  border-radius: 16px;
  background-size: 200% 100%;
  animation: gradient 5s ease infinite;
  margin-top: 90px;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.form-container button:active {
  scale: 0.95;
}

.form-container .form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-container .form-group {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.form-container .form-group label {
  display: block;
  margin-bottom: 5px;
  color: #ffffff;
  font-weight: 600;
  font-size: 12px;
}

.form-container .form-group input,
.form-container .form-group textarea {
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  color: #fff;
  font-family: inherit;
  background-color: transparent;
  border: 1px solid #414141;
}

.form-container .form-group textarea {
  resize: none;
  height: 96px;
}

.form-container .form-group input::placeholder,
.form-container .form-group textarea::placeholder {
  opacity: 0.5;
}

.form-container .form-group input:focus,
.form-container .form-group textarea:focus {
  outline: none;
  border-color: #e81cff;
}

.form-container .form-submit-btn {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  align-self: flex-start;
  font-family: inherit;
  color: #b3b3b3;
  font-weight: 600;
  width: 100%;
  max-width: 150px;
  background: #686868;
  border: 1px solid #414141;
  padding: 12px 16px;
  font-size: inherit;
  gap: 8px;
  margin-top: 8px;
  cursor: pointer;
  border-radius: 6px;
}

.form-container .form-submit-btn:hover {
  background-color: #fff;
  border-color: #fff;
}

/* Responsive styles */
@media (max-width: 576px) {
  .form-container {
    padding: 16px 12px;
  }

  .form-container .form-group input,
  .form-container .form-group textarea {
    padding: 8px 12px;
  }

  .form-container .form-submit-btn {
    padding: 8px 12px;
  }
  .btn-cart-secondary {
    background-color: blue !important;
    border-color: blue !important;
    color: white !important;
    max-width: fit-content;
  }
  .btn-custom-danger.button,
  .btn-cart-primary.button {
    width: 45px; /* Adjusted size */
    height: 45px; /* Adjusted size */
    border-radius: 50%;
    background-color: rgb(20, 20, 20);
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.164);
    cursor: pointer;
    transition: width 0.3s, background-color 0.3s, border-radius 0.3s;
    overflow: hidden;
    position: relative;
  }
}

/* Custom styles for the modal */
/* Custom styles for the modal */
.support-modal-dialog .modal-content {
  max-width: 600px; /* Adjust this value as needed */
  background: none;
  border: none;
}

.support-modal-dialog .modal-body {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Form container */
.form-container {
  width: 100%;
  max-width: 600px; /* Adjust this value as needed */
  background: linear-gradient(#212121, #212121) padding-box,
    linear-gradient(145deg, transparent 35%, #e81cff, #40c9ff) border-box;
  border: 2px solid transparent;
  padding: 32px 24px;
  font-size: 14px;
  font-family: inherit;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
  border-radius: 16px;
  background-size: 200% 100%;
  animation: gradient 5s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.form-container button:active {
  scale: 0.95;
}

.form-container .form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-container .form-group {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.form-container .form-group label {
  display: block;
  margin-bottom: 5px;
  color: #ffffff;
  font-weight: 600;
  font-size: 12px;
}

.form-container .form-group input,
.form-container .form-group textarea {
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  color: #fff;
  font-family: inherit;
  background-color: transparent;
  border: 1px solid #414141;
}

.form-container .form-group textarea {
  resize: none;
  height: 96px;
}

.form-container .form-group input::placeholder,
.form-container .form-group textarea::placeholder {
  opacity: 0.5;
}

.form-container .form-group input:focus,
.form-container .form-group textarea:focus {
  outline: none;
  border-color: #e81cff;
}

.form-container .form-submit-btn {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  align-self: flex-start;
  font-family: inherit;
  color: #717171;
  font-weight: 600;
  width: 100%;
  max-width: 150px;
  background: #313131;
  border: 1px solid #414141;
  padding: 12px 16px;
  font-size: inherit;
  gap: 8px;
  margin-top: 8px;
  cursor: pointer;
  border-radius: 6px;
}

.form-container .form-submit-btn:hover {
  background-color: #fff;
  border-color: #fff;
}

/* Responsive styles */
@media (max-width: 576px) {
  .form-container {
    padding: 16px 12px;
  }

  .form-container .form-group input,
  .form-container .form-group textarea {
    padding: 8px 12px;
  }

  .form-container .form-submit-btn {
    padding: 8px 12px;
  }
}

/* Custom styles for cart items */
.cart-item {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  border-bottom: 1px solid #414141;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.cart-item:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.cart-item-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 8px;
}

.cart-item-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
  word-wrap: break-word; /* Handle long text wrapping */
}

.cart-item-actions {
  display: flex;
  gap: 10px;
}

.modal-title {
  color: white; /* Make the title white */
}

.item-category {
  color: white; /* Make the item category white */
}

.btn-custom-primary:focus,
.btn-custom-primary:active,
.btn-custom-primary:focus:active,
.btn-custom-primary:active:focus {
  background-color: purple !important; /* Custom primary color */
  border-color: #ffffff !important; /* Custom primary border color */
  color: white !important; /* Ensure text color remains white */
  box-shadow: none !important; /* Remove focus shadow */
}

.category-field input[type="date"] {
  flex: 1;
  padding-right: 2rem; /* Adjust padding to make room for the clear button */
}

.category-field button {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 5px;
  border: none;
  background: transparent;
  font-size: 1rem;
  cursor: pointer;
}
@media (max-width: 768px) {
  .category-field input[type="date"] {
    padding-right: 2.5rem; /* Add a bit more padding for smaller screens */
  }

  .category-field button {
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 5px;
    height: 1.5rem;
    width: 1.5rem;
  }
}

/* Style for the dropdown menu */
.support-modal-dialog .react-select__menu {
  background-color: rgb(0, 0, 0) !important;
}

/* Style for the dropdown options */
.support-modal-dialog .react-select__option {
  background-color: rgb(0, 0, 0) !important;
  color: black !important;
}

/* Style for the dropdown options on hover */
.support-modal-dialog .react-select__option:hover,
.support-modal-dialog .react-select__option--is-focused {
  background-color: #f0f0f0 !important;
  color: black !important;
}

/* Style for the selected option */
.support-modal-dialog .react-select__option--is-selected {
  background-color: #007bff !important;
  color: white !important;
}

/* Style for the dropdown control (the main input area) */
.support-modal-dialog .react-select__control {
  background-color: white !important;
  border-color: #ced4da !important;
}

/* Style for the selected value */
.support-modal-dialog .react-select__single-value {
  color: black !important;
}

/* Style for the input text */
.support-modal-dialog .react-select__input-container,
.support-modal-dialog .react-select__input-container input {
  color: black !important;
}

/* Style for the placeholder */
.support-modal-dialog .react-select__placeholder {
  color: #6c757d !important;
}

/* Bottom Bar Styles */
.bottom-bar {
  height: 60px;
  background-color: #454545;
}

.add-button {
  background-color: transparent;
  border: none;
  color: #000000;
  padding: 8px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.add-button:hover {
  color: #000000;
}

/* Adjust main content padding */
.container {
  padding-bottom: 70px; /* Increase this value if needed */
}

/* Ensure last category section doesn't overlap with bottom bar */
.category-section:last-child {
  margin-bottom: 70px;
}

